jQuery(document).ready(function ($) {
    $(".cat_title").on('click', function () {
        $(this).parent().toggleClass("active");
        $(".category-dropdown").toggleClass("active");
        $(".tags").removeClass("active");
        $(".tags-dropdown").removeClass("active");
    });

    $(".tag_title").on('click', function () {
        $(this).parent().toggleClass("active");
        $(".tags-dropdown").toggleClass("active");
        $(".category").removeClass("active");
        $(".category-dropdown").removeClass("active");
    });
    var selectedTags = [];
    $('.tag_value').on('click', function () {
        console.log(selectedTags);
        var selectedTag = $(this).text();
        if (selectedTags.indexOf(selectedTag) === -1) {
            selectedTags.push(selectedTag);
            $('.tag_line').append('<span>' + selectedTag + '</span>');
            get_posts();
        }
        $(".tags").removeClass("active");
        $(".tags-dropdown").removeClass("active");
    });

    var selectedCategories = [];
    $('.cat_value').on('click', function () {
        var selectedCat = $(this).text();
        if (selectedCategories.indexOf(selectedCat) === -1) {
            selectedCategories.push(selectedCat);
            $('.cat_line').append('<span>' + selectedCat + '</span>');
            get_posts();
        }
        $(".category").removeClass("active");
        $(".category-dropdown").removeClass("active");
    });

    $(document).on('click', ".cat_line span, .tag_line span", function () {
        $(this).remove();
        get_posts();
    });

    $('.search-form').append("<span class='flush'></span>");
    $('.flush').on('click', function () {
        $('.search-field').val('').change();
    });

    $(".clear_filter").on("click", function () {
        location.reload();
    });


    function get_posts() {
        var selectedTags = [];
        var selectedCats = [];
        $('.tag_line span').each(function () {
            selectedTags.push($(this).text());
        });

        $('.cat_line span').each(function () {
            selectedCats.push($(this).text());
        });

        if (selectedTags.length > 0 || selectedCats.length > 0) {
            $(".blog-ajax").addClass("active");
        } else {
            $(".blog-ajax").removeClass("active");
        }

        $.ajax({
            type: 'POST',
            // eslint-disable-next-line
            url: ajaxurl,
            data: {
                action: 'blog_ajax_handler',
                cats: selectedCats,
                tags: selectedTags
            },
            success: function (response) {
                $('.blog_loop').html(response);
                $(".blog_pagin").hide();
            },
            error: function (xhr, status, error) {
                console.error(error);
            }
        });
    }

    $(".loader").hide();
});

window.addEventListener("DOMContentLoaded", () => {
    const selectors = {
        header: "[data-header-selector]",
        dropdown: "[data-header-dropdown]",
        dropdownMenu: "[data-header-dropdown-menu]",
        dropdownTrigger: "[data-header-dropdown-trigger]",
        dropdownTriggerMobile: "[data-header-dropdown-trigger-mobile]",
        dropdownClose: "[data-header-dropdown-close]"
    };

    const headers = document.querySelectorAll(selectors.header);

    const dropdown = document.querySelector(selectors.dropdown);
    const dropdownMenu = document.querySelector(selectors.dropdownMenu);
    const dropdownTriggerMobile = document.querySelector(selectors.dropdownTriggerMobile);
    const dropdownTrigger = document.querySelector(selectors.dropdownTrigger);
    const dropdownClose = document.querySelector(selectors.dropdownClose);
    const html = document.querySelector("html");

    dropdownTriggerMobile.addEventListener("click", () => {
        dropdown.classList.add("opened");
        html.classList.add("overflow-hidden");
    })

    dropdownTrigger.addEventListener("click", () => {
        dropdown.classList.add("opened");
        html.classList.add("overflow-hidden");
    })

    dropdownClose.addEventListener("click", () => {
        dropdown.classList.remove("opened");
        html.classList.remove("overflow-hidden");
    })

    dropdown.addEventListener("click", (e) => {
        if (dropdown.classList.contains("opened") && !dropdownMenu.contains(e.target) && !dropdownTrigger.contains(e.target) && !dropdownTriggerMobile.contains(e.target)) {
            dropdown.classList.remove("opened");
            html.classList.remove("overflow-hidden");
        }
    })

    const checkScroll = () => {
        if (window.scrollY > 100) {
            headers.forEach(item => item.classList.add("header--scrolled"));
        } else {
            headers.forEach(item => item.classList.remove("header--scrolled"));
        }
    }

    checkScroll();

    window.addEventListener("scroll", checkScroll);
})